import './About.css'
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@emotion/react';
import headerImage from '../../static/aboutUsImage.png'
import teamImg from '../../static/medicalTeam.png'
import { Box, Button, Typography } from '@mui/material';
import noah from '../../static/teamHeadshots/noah.png'
import chris  from '../../static/teamHeadshots/chris.png'
import cw from '../../static/teamHeadshots/cw.png'
import alex from '../../static/teamHeadshots/alex.png'
import ford from '../../static/teamHeadshots/ford.png'
import luis from '../../static/teamHeadshots/luis.png'
import ethan from '../../static/teamHeadshots/ethan.png'
import jasine from '../../static/teamHeadshots/jasine.png'
import caedyn from '../../static/teamHeadshots/caedyn.png'
import gus from '../../static/teamHeadshots/gus.png'
import vicky from '../../static/teamHeadshots/vicky.png'
import ganesh from '../../static/teamHeadshots/ganesh.png'
import maxine from '../../static/teamHeadshots/maxine.png'
import felix from '../../static/teamHeadshots/felix.png'
import josh from '../../static/teamHeadshots/josh.jpeg'
import nick from '../../static/teamHeadshots/nick.png'
import adrienne from '../../static/teamHeadshots/adrienne.jpg'
import {useNavigate} from 'react-router-dom'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const employees = [
    {
      image: nick,
      name: 'Nicholas Steele',
      job: 'Chief Executive Officer',
      linkedIn: '',
    },
    {
      image: noah,
      name: 'Noah Hanson',
      job: 'President',
      linkedIn: 'https://www.linkedin.com/in/noahhanson/',
    },
    {
      image: chris,
      name: 'Christopher Oppernmann',
      job: 'Business Operations Director',
      linkedIn: 'https://www.linkedin.com/in/christopheroppermann/',
    },
    {
      image: cw,
      name: 'Charles CW Hall',
      job: 'Clinical Operations Director',
      linkedIn: '#',
    },
    {
      image: alex,
      name: 'Alexander Moldenhawer',
      job: 'Data Architect',
      linkedIn: '',
    },
    {
      image: jasine,
      name: 'Jasine Giles',
      job: 'Revenue Cycle Manager',
      linkedIn: '',
    },
    {
      image: caedyn,
      name: 'Caedyn Wheeler',
      job: 'Business Operations Specialist',
      linkedIn: 'https://www.linkedin.com/in/caedyn-wheeler-84a664188/',
    },
    {
      image: gus,
      name: 'August Eckstein',
      job: 'Business Operations Assistant',
      linkedIn: 'https://www.linkedin.com/in/august-eckstein-3455b1165/',
    },
    {
      image: vicky,
      name: 'Vicky Harper',
      job: 'Controller',
      linkedIn: '',
    },
    {
      image: ganesh,
      name: 'Ganesh Acharya',
      job: 'Billing & Data Analyst',
      linkedIn: 'https://www.linkedin.com/in/ganeshacharyaa/',
    },
    {
      image: maxine,
      name: 'Maxine Cox',
      job: 'Data Analyst',
      linkedIn: 'https://www.linkedin.com/in/maxine-cox1251/',
    },
    {
      image: ford,
      name: 'Ford Hirsch',
      job: 'Lead Software Engineer',
      linkedIn: 'https://www.linkedin.com/in/ford-hirsch-8a0625186/',
    },
    {
      image: ethan,
      name: 'Ethan Lee',
      job: 'Systems Architect',
      linkedIn: 'https://www.linkedin.com/in/ethan-m-lee-8716711a0/',
    },
    {
      image: adrienne,
      name: 'Adrian Colaianni',
      job: 'Security Engineer',
      linkedIn: '',
    },
    {
      image: josh,
      name: 'Josh Daniel',
      job: 'DevOps Engineer',
      linkedIn: '',
    },
    {
      image: felix,
      name: 'Felix Chen',
      job: 'Software Engineer',
      linkedIn: '',
    },
    {
      image: null,
      name: 'Daniel Hong',
      job: 'Software Engineer',
      linkedIn: '',
    },  
    {
      image: null,
      name: 'Alexandru Manaila',
      job: 'Software Engineer',
      linkedIn: '',
    },
    {
      image: luis,
      name: 'Luis Ramirez',
      job: 'Software Engineer',
      linkedIn: 'https://www.linkedin.com/in/luis-ramirez-web-devloper/',
    },
  ];
  

export default function About(){
    const theme = useTheme()
    let navigate = useNavigate()

    const toInquiry = () =>{        
        navigate('/inquiry')
    }

  return(
    <>
        {/* hero content */}
        
        <Grid container className="flex-col max-width hero-item" justifyContent={'space-between'} sx={{margin:'auto', marginBottom:'72px', gap:'70px', }}>
            
          <Grid xs={10} sm={10} md={4} lg={6}  className="flex-col"  marginTop={'57px'}>
              <Typography sx={{fontWeight:600, fontSize:'20px', lineHeight:'123.5%', letterSpacing:'0.25px'}} 
              color={theme.palette.text.primary}>We <span className='blue'>alleviate</span> provider workload, <span className='blue'>improve</span> patient care, and <span className='blue'>reduce</span> costs throughout your health system</Typography>
              <br></br>
              <Typography sx={theme.typography.body1}>Outpatient physician practices, hospital emergency rooms, hospitalist departments, and molecular laboratories all benefit from our consulting patho-pharmacology services. </Typography>
              <br/>
              <Typography sx={theme.typography.body1}>We bill insurance payers directly for our service, so your practice doesn't have to find the budget to recruit and hire the physicians and pharmacists needed to research and make therapeutic recommendations.</Typography>
          </Grid>
          <Grid xs={10} md={5}  className='flex-row-center' alignItems={'flex-start'}>
              <img src={headerImage} alt='clinician logo' className='animated-doctor'/>
          </Grid>
        </Grid>

        <Grid container className="flex-col max-width  hero-item" justifyContent={'space-between'} sx={{marginBottom:'72px', gap:'70px', margin:'auto', flexWrap:'wrap-reverse'}}>
          <Grid xs={10} md={6}  className='flex-row-center' >
              <img src={teamImg} alt='Medical Team' className='staff-picture'/>
          </Grid>
          
          <Grid xs={10} md={4} lg={5} className="flex-col">
              <Typography sx={{ ...theme.typography.h4, fontWeight:600,}}>Our clinicians</Typography>
              <br/>
              <Typography sx={{...theme.typography.body1}}>Our team consists of pathologists, practitioners, and clinical pharmacists who assess your patients’ diagnoses, allergies, medication regimens, and lab results to give patient-specific, evidence-based recommendations. Our work allows prescribers to make the safest, most effective medication choices.</Typography>
          </Grid>

        </Grid>

        {/* start a conversation */}
        <Grid container className='flex-col-center' sx={{bgcolor:'#10A5E7', marginTop:'72px'}} >
            <Grid xs={12} className="flex-row-center about-inquiry-container max-width" sx={{gap:'80px', p:'32px', flexWrap:'wrap'}}>
                <Typography sx={{...theme.typography.h5, color:'white', fontWeight:600}}>Advancing  your healthcare practice with BeyondMD</Typography>
                <Button 
                sx={{
                    ...theme.typography.body2, 
                    color:' #10A5E7', 
                    bgcolor:' white', 
                    borderRadius:'21px', 
                    p:'8px 22px', 
                    textTransform:'none',
                    border:'1px solid #fff',
                    '&:hover':{
                        color:'#fff',
                        background:'#0075a8'
                    }
                }}
                className='about-us-cta-btn'
                onClick={toInquiry}
                >Start a conversation</Button>
            </Grid>
        </Grid>
        
        {/* team headshots */}
        <Grid container className='flex-col-center' sx={{bgcolor:'rgba(16, 165, 231, 0.08)', p:'40px 50px 72px',gap:'32px'}}>
            <Grid xs={12}>
                <Typography sx={{fontWeight:600, fontSize:'34px', textAlign:'center'}}>Office staff</Typography>
            </Grid>
            
            <Grid xs={12} className='flex-row max-width' flexWrap={'wrap'} justifyContent={'center'} gap={'48px'}>
                    {employees.map((employee, index ) =>{
                        return(
                            <Link key={index} href={employee.linkedIn? employee.linkedIn : 'https://www.linkedin.com/company/beyondmdaco/mycompany/'} target="_blank" sx={{color:'black', textDecoration:'none'}}>
                            <Box className='flex-col-center employee-card'>
                                <Box sx={{position:'relative'}}>
                                {
                                  employee?.image ?
                                    <img src={employee.image} style={{width:'200px', height:'200px', borderRadius:'16px',top:0, left:0}}/>
                                    :
                                    <Box className='flex-col-center' sx={(theme) => ({width:200, height:200,border:`1px dashed ${theme.palette.text.disabled}`, borderRadius:'16px',top:0, left:0})}>
                                      <AccountCircleOutlinedIcon sx={(({palette}) => ({color: palette.text.disabled, height:44, width:44}))} />
                                    </Box>
                                }
                                <LinkedInIcon sx={{height:'24px', width:'24px', position:'absolute', color:'rgba(255, 255, 255, 0.56)', top:'80%', left:'80%'}} />
                                </Box>
                                <Box className='flex-col-center' gap={'4px'}>
                                    <Typography sx={{fontWeight:'600'}}>{employee.name}</Typography>
                                    <Typography sx={{...theme.typography.body2}} >{employee.job}</Typography>
                                </Box>
                                
                            </Box>
                            </Link>
                        )
                    })}    
            </Grid>
        </Grid> 
    </>
  )

}
