import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

//CSRF Component (similar to django CSRF tag)
const CSRFToken = () => {
    const [csrftoken, setcsrftoken] = useState('');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await axios.get(`https://ct.beyondmd.care/api/csrf-token`, {
                    xsrfCookieName:'csrftoken',
                    xsrfHeaderName: 'X-CSRFTOKEN',
                    withCredentials: true
                });
                
            } catch (err) {

            }
        };

        fetchData();
        setcsrftoken(Cookies.get('csrftoken'));
        setLoading(false)
    }, []);

    if(loading){
        return <h1>Loading...</h1>
    }

    return (
        <input type='hidden' name='csrfmiddlewaretoken' value={csrftoken} />
    );
};

export default CSRFToken;



