import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTheme }from '@emotion/react'
import Box from'@mui/material/Box'
import Link from '@mui/material/Link';











export default function QAAccordion(props){
    const {question, answer, isBilling} = props
    const theme = useTheme()

    return(
        <Accordion sx={{ border: '1px solid #ececec'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography sx={{...theme.typography.subtitle1, fontWeight:'600'}}>
                    {question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {isBilling? 
                        <>
                        <Typography sx={{...theme.typography.body1}}>
                        We accept all forms of payment. You can pay your bill directly online via the <Link sx={{color:'#10A5E7'}} href='https://patientportal.advancedmd.com/account/logon?lk=147397'>patient portal</Link>, but note that you may need to create an account if you're not already signed up. Alternatively, you can send payment to:
                        </Typography>   
                        <Box sx={{marginLeft:2,marginTop:2}}> 
                            <Typography sx={{...theme.typography.body1}}>
                                Aegis Health Services <br/>
                                10 Glenlake Pkwy <br/>
                                North Tower Suite 575 <br/>
                                Atlanta, GA 30238
                            </Typography>
                        </Box> 
                        </>
                    :
                    <Typography sx={{...theme.typography.body1}}>
                        {answer}
                    </Typography>   
                }
            </AccordionDetails>
        </Accordion>
    )

}
