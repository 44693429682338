
import "./App.css"
import { Route, Routes } from 'react-router';
import Header from './layout/Header';
import Home from './pages/home/Home';
import Footer from "./layout/Footer";
import Inquiry from "./pages/inquiry/Inquiry";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import About from "./pages/about/About";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Careers from "./pages/careers/Careers";
import FAQs from "./pages/FAQs/FAQs";
import ScrollToTop from "./layout/ScrollToTop";

const theme = createTheme();

function App() {
  return (
  <>
  <ThemeProvider theme={theme}>
  <ScrollToTop />
    <Header/>
    <Routes>
        <Route path='/'  element={<Home/>}/>
        <Route path='/inquiry' element={<Inquiry/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/careers' element={<Careers/>}  />
        <Route path='/patients/FAQs' element={<FAQs/>} />
    </Routes>
    <Footer/>
  </ThemeProvider>
  </>
  );
}

export default App;
