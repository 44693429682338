import { Fade, Backdrop, Modal} from '@mui/material';
import handSign from '../../../static/peace_sign.png'
import { Box, Button} from '@mui/material';
import { buttonStyles, style } from '../inquiryHelpers'
import { useNavigate } from 'react-router-dom'



const SuccessModal = ({open, handleModal})=>{

    const navigate = useNavigate()

    return(
        <Modal
            disableAutoFocus={true}
            open={open}
            onClose={handleModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500, }, }} >
            <Fade in={open}>
            <Box sx={style}>
                <div className='modal-item'>
                    <img src={handSign} className='hand-sign' alt='Peace Hand Signal'/>
                </div>
                <div className='modal-item'>
                    <h1>Great!</h1>
                    <p>We'll send a booking invitation to your email.</p>
                    <Button onClick={() => navigate('/')} variant='contained' sx={buttonStyles}> Back to home</Button>
                </div>
            </Box>
            </Fade>
        </Modal>
    )

}

export default SuccessModal;