import './Careers.css'
import { Typography, Chip, Box} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; 
import {useTheme} from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationIcon from '../../static/careerIcons/location-person.png'
import { Link } from 'react-router-dom';



const jobPostings = [
    {
        title:'Clinical Pharmacist',
        description:"As a BeyondMD Clinical Pharmacist, you will perform Pharmaceutical care services, which include but are not limited to: assessing patient needs, incorporating age, laboratory diagnostics (including CMP, Lipid panels, A1c, culture and sensitivity, molecular tests for infections, pharmacogenetic tests, among others), and disease-specific characteristics into drug therapy and patient education, recommending and or adjusting appropriate care according to patient response, and providing clinical interventions to detect, mitigate, and prevent medication adverse events.",
        tag: 'Health',
        location: 'Remote',
        commitment: 'Full-time/Part-time',
        link:'https://www.pharmdc.org/about-opportunities'
    },
]




export default function Careers(){
    const theme = useTheme()


    return(
        <>
        <Box className='career-container'>
            <Grid container className='flex-col-center max-width' gap={'56px'} m={'auto'} p='56px 0px'>

                <Grid xs={10} lg={12} className='careers-header' >
                    <Typography sx={{fontWeight:'600', fontSize:'30px'}}>Revolutionize the Healthcare Industry</Typography>
                    <br/>
                    <Typography sx={{fontWeight:'400', fontSize:'16px'}}>Our philosophy is simple - hire a team of skilled, professional, and passionate people. We are currently looking to expand our team to build the future of healthcare.</Typography>
                </Grid>

                {jobPostings.map( (job,index) =>{
                    return (
                        <Link key={index} reloadDocument target='_blank' to={job.link} className='flex-col-center' style={{color:'inherit', textDecoration:'none'}}>
                        <Grid  xs={10} lg={12} className='flex-col job-card' sx={{p:'32px', gap:'12px'}} >
                            
                            <Grid className='flex-row-center' gap={'16px'}>
                                <Typography sx={{...theme.typography.subtitle1, fontWeight:'600'}}>{job.title}</Typography>
                                <Chip size='small' label={job.tag} sx={{fontWeight:'400'}}/>
                            </Grid>

                            <Grid>
                                <Typography sx={{...theme.typography.body1}}>{job.description}</Typography>
                            </Grid>

                            <Grid className='flex-row' gap={'16px'}>
                                <Box className='flex-row-center' gap={'8px'}>
                                    <AccessTimeOutlinedIcon sx={{color:theme.palette.action.active}}/>
                                    <Typography sx={{...theme.typography.body2}}>{job.commitment}</Typography>
                                </Box>
                                <Box className='flex-row-center' gap={'8px'}>
                                    <img src={LocationIcon} alt='location icon' height={'24px'} width={'24px'}/>
                                    <Typography sx={{...theme.typography.body2}}>{job.location}</Typography>
                                </Box>
                            </Grid>
                            
                        </Grid>
                        </Link>
                        
                    )
                })

                }

            </Grid>
        </Box>
        </>
    )
}