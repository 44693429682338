
import './Inquiry.css'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { TextField,   FormControl, Select, MenuItem, InputLabel} from '@mui/material';
import { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Grid from '@mui/material/Unstable_Grid2'; 
import { Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material'
import { isValidForm, stateList, toQueryParams } from './inquiryHelpers';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import SuccessModal from './helperComponents/SuccessModal';
import CustomTextField from './helperComponents/CustomTextField';
import CSRFToken from '../../app/CSRFToken';



const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  practiceName: "",
  state:"",
  patientPopulation: "",
  practiceType: "",
  patientSpecialty: "",
  additionalInfo:""
};

export default function Inquiry(){
    const theme = useTheme()
    const [open, setOpen ] = useState(false)
    const handleModal = () => setOpen(!open)
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState(initialValues)
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true);
      
        if (isValidForm(formValues)) {
          setLoading(true);
      
          // Constructing the URL with query parameters
          const queryParams = toQueryParams({
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            email: formValues.email,
            phone: formValues.phone,
            practice_name: formValues.practiceName,
            state_of_practice: formValues.state,
            patient_population: formValues.patientPopulation,
            practice_type: formValues.practiceType,
            specialty: formValues.patientSpecialty,
            additional_info: formValues.additionalInfo
          });
      
          const endpoint = `https://ct.beyondmd.care/api/sales-site/inquiry?${queryParams}`;
          
          

          try {
            let res = await axios.get(endpoint, {
                headers: {
                    'X-CSRFToken': Cookies.get("csrftoken")
                },
                withCredentials: true
            });
      
            setTimeout(() => {
              setLoading(false);
              handleModal();
              setFormValues(initialValues);
              setSubmitted(false);
            }, 500);
      
          } catch (error) {
            console.error(error);
          }
        }
      };

    const handleChange = (event) => {
      const { name, value } = event.target;
  
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    };


    
    return(
        <>

            <Grid container className='flex-row-center' p={'57px 30px'} sx={{ background: 'linear-gradient(to right, white 70%, rgba(16, 165, 231, 0.12) 70%)' }}>
                <Box className='flex-row max-width inquiry-container' gap={'20px'} justifyContent={'space-between'} >
                    {/* Left side text */}
                    <Grid xs={11} lg={5.1} className='flex-col' gap={'40px'} >
                        <Grid className='flex-col' gap={'16px'}>
                            <Typography sx={{fontWeight:600, fontSize:'30px', lineHeight:'123%', letterSpacing:'0.25px'}}>Request free consultation</Typography>
                            <Typography sx={{...theme.typography.body1}} >Join a live, free consultation to see why some of the smartest practices use BeyondMD to enhance patient outcomes and boost revenue generation.</Typography>
                        </Grid>
                        <Grid className='flex-col' gap={'17px'} >
                                <Typography sx={{...theme.typography.subtitle1, fontWeight:600}}>Questions?</Typography>
                                <Box className='flex-row' sx={{color:'#0C93E0', alignItems:'flex-end'}} gap={1}>
                                    <CallOutlinedIcon/>
                                    <Typography sx={{...theme.typography.subtitle2, fontWeight:600}}>Give us a call at +1 (404) 793 - 8372</Typography>
                                </Box>
                                <Box className='flex-row' sx={{color:'#0C93E0', alignItems:'flex-end'}} gap={1}>
                                    <MailOutlinedIcon/>    
                                    <Typography sx={{...theme.typography.subtitle2, fontWeight:600}}>Email us to info@beyondmd.care</Typography>
                                </Box>
                        </Grid>
                    </Grid>
                    {/* Right side inquiry form */}
                    <Grid xs={11} lg={6.2} className='inquiry-form'>
                        <CSRFToken/>
                        <Typography sx={{ ...theme.typography.subtitle1, fontWeight: 600 }}>
                            Please enter your information
                        </Typography>
                        <Grid component="form" container onSubmit={handleSubmit} sx={{ mt: 3 }} spacing={2}>
                            {[
                                ['firstName', 'First Name*', 'given-name', formValues.firstName],
                                ['lastName', 'Last Name*', 'family-name', formValues.lastName],
                                ['email', 'Email Address*', 'email', formValues.email],
                                ['phone', 'Phone', 'phone', formValues.phone],
                                ['practiceName', 'Practice name*', 'practice name', formValues.practiceName],
                                ['patientPopulation', 'Patient Population*', 'patient population', formValues.patientPopulation],
                                ['practiceType', 'Practice type*', 'practice type', formValues.practiceType],
                                ['patientSpecialty', 'Patient specialty*', 'patient specialty', formValues.patientSpecialty],
                            ].map(([name, label, autoComplete, value], index) => (
                                <Grid xs={12} sm={6} key={name}>
                                    <CustomTextField 
                                        name={name}
                                        label={label}
                                        type={name}
                                        value={value}
                                        autoComplete={autoComplete}
                                        handleChange={handleChange}
                                        errorCondition={!value && submitted}
                                    />
                                </Grid>
                            ))}
                            
                            <Grid xs={12}>
                                <FormControl fullWidth className="css-select" error={!formValues.state && submitted}>
                                    <InputLabel id="state-label">State of practice*</InputLabel>
                                    <Select
                                        labelId="state-label"
                                        value={formValues.state}
                                        name='state'
                                        label="State Of Practice"
                                        onChange={handleChange}
                                    >
                                        {stateList.map((state) => (
                                            <MenuItem value={state.name} key={state.name}>
                                                {state.abbreviation}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText display={!formValues.state && submitted ? 'show' : 'none'}>
                                        {!formValues.state && submitted ? "This field is required" : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    name="additionalInfo"
                                    label="Additional information related to your business needs"
                                    type="additional info"
                                    multiline
                                    rows={4}
                                    value={formValues.additionalInfo}
                                    onChange={handleChange}
                                    autoComplete="additional info"
                                />
                            </Grid>
                            
                            <Grid justifyContent="flex-start">
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    loading={loading}
                                    sx={{ mt: 3, mb: 2, bgcolor: '#00B295', borderRadius: 21, '&:hover': { background: '#009978' } }}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <SuccessModal open={open} handleModal={handleModal}/>                 
            
        </>
    )

}