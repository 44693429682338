import Grid from '@mui/material/Unstable_Grid2'; 
import './Home2.css'
import heroImage from '../../static/hero-image.png'
import resurgiaLogo from '../../static/partnerCompanies/resurgia.png'
import entLogo from '../../static/partnerCompanies/ent.png'
import phoebeLogo from '../../static/partnerCompanies/phoebe.png'
import drDebra from '../../static/homeIcons/dr_debra.png'
import drJohn from '../../static/homeIcons/dr_john.png'
import washingtonCenterLogo from '../../static/homeIcons/washington_center.png'
import moneyStat from '../../static/money_stat.png'
import uptrendArrow from '../../static/homeIcons/uptrendArrow.png'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react';
import { Button, Typography, Box } from '@mui/material';
import easy from '../../static/homeIcons/easy.png'
import find from '../../static/homeIcons/find.png'
import analyze from '../../static/homeIcons/analyze.png'

export default function Home(){
    let navigate = useNavigate(); 
    const theme = useTheme()

    const toInquiry = () =>{
        navigate('/inquiry')
    }

    return(
        <>
        {/* Hero Container */}
        <Grid container className='flex-row home-hero-container max-width'  sx={{gap:'37px', p:'48px 0px', flexWrap:'wrap',m:'auto', justifyContent:'space-between', alignItems:'center'}}>
            <Grid xs={10}  md={5.2} lg={5.2}  className='flex-col' gap={'24px'}>
                <Typography className='home-hero-title' sx={{fontWeight:600, fontSize:'34px', lineHeight:'123.5%'}}>Enhance patient outcomes, boost revenue generation</Typography>
                <Typography sx={{...theme.typography.body1}}>BeyondMD provides better population health management at your healthcare practice through a proprietary, proactive, and data-driven care continuum.</Typography>
                <Button className='hover-btn' sx={{
                    ...theme.typography.body2, 
                    bgcolor:'#00B295', 
                    borderRadius:'21px', 
                    width:'170px', 
                    textTransform:'none', 
                    color:theme.palette.secondary.contrastText, 
                    p:'8px, 22px',
                        '&:hover':{
                            background: '#009978',
                            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                        }}} 
                    onClick={toInquiry}>Start a conversation</Button>
            </Grid>
            <Grid xs={10}  md={5.3} lg={6.3}  className='flex-row-center'>
                <img src={heroImage}  className='hero-image' alt="Healthcare workers" />
            </Grid>
            
        </Grid>

        {/* Brand Logos */}
        <Box className='flex-row-center' sx={{width:'100%', bgcolor:'#10A5E7',}}>
            <Grid container className='flex-row-center max-width' sx={{ gap:'10px', p:'10px', flexWrap:'wrap', width:'100%'}}>
                <Grid xs={6} sm={3} lg={2}  className='flex-row-center' p={'10px'}>
                    <img src={resurgiaLogo} height={'40px'} className='brand-img' alt='resurgia logo'/>
                </Grid>
                
                <Grid xs={6} sm={3} lg={2}  className='flex-row-center' p={'10px'}>
                    <img src={phoebeLogo} height={'30px'} className='brand-img' alt='phoebe logo'/>
                </Grid>

                <Grid  xs={6} sm={3} lg={2}  className='flex-row-center' p={'10px'}>
                    <img src={entLogo} height={'30px'} className='brand-img' alt='ent logo'/>
                </Grid>
            </Grid> 
        </Box>
        
        {/* About container */}
        <Box className='flex-row-center' sx={{width:'100%', bgcolor:'rgba(16, 165, 231, 0.12)',}}>
            <Grid container className='flex-col max-width' justifyContent={'center'} sx={{ gap:'40px', p:'56px 0px 116px'}}> 
                <Grid xs={10} className='flex-row-center'>
                    <Typography className='home-about-title' sx={{ textAlign:'center', fontWeight:600, fontSize:'24px', letterSpacing:'0.15px', color:theme.palette.text.primary}} >BeyondMD is your ticket to value-based healthcare success </Typography>
                </Grid>
                
                <Grid xs={10} className='flex-row' sx={{gap:'40px', justifyContent:'space-between', flexWrap:'wrap'}}>
                    <Grid xs={12} md={3}   className='flex-row' gap={'16px'}>
                        <img src={analyze} alt='Analyze Icon' style={{height:'48px', width:'48px'}}/>
                        <Grid xs={10} className='flex-col' gap={'4px'}>
                            <Typography sx={{fontWeight:600,fontSize:'16px', color:theme.palette.text.primary}}>Analyze your data</Typography>
                            <Typography sx={{...theme.typography.body2, color:theme.palette.text.primary}}>We perform data analysis of your practice to outline where you could be making more revenue and improving patient quality scores.</Typography>
                        </Grid>
                    </Grid>

                    <Grid xs={12} md={3}   className='flex-row max-width' gap={'16px'}>
                        <img src={find} alt='Find Icon' style={{height:'48px', width:'48px'}}/>
                        
                        <Grid xs={10} className='flex-col' gap={'4px'}>
                            <Typography sx={{fontWeight:600,fontSize:'16px', color:theme.palette.text.primary}}>Find your priorities as a practice</Typography>
                            <Typography sx={{...theme.typography.body2, color:theme.palette.text.primary}}>Recommendations will be implemented based on your priorities as a practice.</Typography>
                        </Grid>
                    </Grid>

                    <Grid xs={12} md={3}   className='flex-row max-width' gap={'16px'}>
                        <img src={easy} alt='Easy Icon' style={{height:'48px', width:'48px'}}/>
                        
                        <Grid xs={10} className='flex-col' gap={'4px'}>
                            <Typography sx={{fontWeight:600,fontSize:'16px', color:theme.palette.text.primary}}>Easy, accessible reviews</Typography>
                            <Typography sx={{...theme.typography.body2, color:theme.palette.text.primary}}>Our clinicians can perform thorough and customized reviews services using patient records from your EMR.</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
       
        {/* Review and Stat container */}
        <Grid container className="flex-col-center review-container max-width"  sx={{ gap:'40px', p:'20px 0px 72px', margin:'auto'}}>


            <Grid xs={9}  className='flex-row stat-container' bgcolor={'#fff'} >
                <Box sx={{bgcolor:'#00B295',   p:'26px 56px', gap:'10px'}} className='flex-row-center money-stat'>
                    <img src={uptrendArrow} alt='Upward Trend Arrow' className='uptrend' height={'48px'} width={'48px'}/>
                    <Typography sx={{fontWeight:600, fontSize:'34px', color:'#fff'}}>$31,430</Typography>
                </Box>
                <Box sx={{background:'#fff',  width:'100%', p:'42px 48px', alignItems:'center', justifyContent:'space-between'}} className='flex-row home-stat-text'>
                    <Typography sx={{...theme.typography.body1, color:theme.palette.text.primary}}>monthly savings per 1,000 patients </Typography>
                    <Button sx={{
                    border:' 1px solid rgba(0, 178, 149, 0.5)', 
                    borderRadius:'21px', 
                    color:'#00B295', 
                    textTransform:'none', 
                    fontWeight:400, p:'8px 22px',
                    height:'36px'
                    }} onClick={toInquiry}>Start saving </Button>
                </Box>
            </Grid>

            <Grid xs={9} className='flex-row review-item max-width' sx={{gap:'16px'}}>
                <img src={drDebra} className='review-avatar' alt='Dr.Debra Headshot' style={{width: '56px', height: '56px',}} />
                <Grid className='flex-col' sx={{gap:'8px'}}>
                    <Typography sx={{...theme.typography.subtitle1, color:theme.palette.text.primary, fontWeight:600}}>“I have been in practice 17 years and I have seen a tremendous improvement in patient care and compliance since implementing our current education program with BeyondMD. They take ownership of our patients, and it shows in their work, day in and day out. Their commitment to our practice and our patients has been outstanding ... Our patients are now engaged, educated, and motivated to make a change in their own health care.”</Typography>
                    <Typography sx={{...theme.typography.body2, color:theme.palette.text.disabled}}>Dr. Debra Curtis, M.D. Internal Medicine</Typography>
                </Grid>
            </Grid>
            <Grid xs={9} className='flex-row review-item max-width' sx={{gap:'16px'}}>
                <img src={drJohn} className='review-avatar' alt='Dr.Debra Headshot' style={{width: '56px', height: '56px',}}/>
                <Grid className='flex-col' sx={{gap:'8px'}}>
                    <Typography sx={{...theme.typography.subtitle1, color:theme.palette.text.primary, fontWeight:600}}>“BeyondMD staff are capable, compassionate professionals that serve as an extension of the special care our patients have come to expect from our practice. They work hand in hand with our staff to provide the care, testing, and counseling our patients need and deserve.”</Typography>
                    <Typography sx={{...theme.typography.body2, color:theme.palette.text.disabled}}>Dr. John F. Dombrowski, M.D. FASA</Typography>
                    <img src={washingtonCenterLogo} alt='The Washington Center Logo' style={{width: '163px', height: '32px'}}/>
                </Grid>
            </Grid>

        </Grid>

        {/* CTA container */}
        <Grid container className='flex-row-center cta-container' sx={{flexWrap:'wrap', gap:'80px',background:'#10A5E7', p:'32px'}} >
            <Typography sx={{fontWeight:600, fontSize:'22px', color:'#fff', maxWidth:'314px'}}>Helping healthcare practices get out of this world results  </Typography>
            <Button onClick={toInquiry} 
            sx={{
                background:'#fff', 
                borderRadius:'21px', 
                p:'8px 22px', 
                color:'#10A5E7', 
                textTransform:'none', 
                fontWeight:400,
                border:'1px solid #fff',
                '&:hover':{
                    color:'#fff',
                    
                    background:'#0075a8'
                }
            }} >Start a conversation</Button>
        </Grid>

        </>
    )
}