import { TextField } from '@mui/material';


const CustomTextField = ({ name, label, type, value, autoComplete, handleChange, errorCondition }) => (
    <TextField
        fullWidth
        name={name}
        label={label}
        type={type}
        value={value}
        autoComplete={autoComplete}
        onChange={handleChange}
        error={errorCondition}
        helperText={errorCondition ? "This field is required" : ''}
    />
);

export default CustomTextField;