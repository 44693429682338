import './FAQs.css'
import {Box, Typography} from '@mui/material'
import { useTheme } from '@emotion/react';
import QAAccordion from './helpers/QAAccordion';
import Grid from '@mui/material/Unstable_Grid2'; 










export default function FAQs(){
    const theme = useTheme()

    const FAQs = [
        {
            Q:"Who is Aegis/BeyondMD? I've never heard of them.",
            A:"We are a clinic that offers preventative services, specializing in the management of chronic conditions, and medication therapy. We work with practices to provide interprofessional consultation to improve your health outcomes."
        },
        {
            Q:'Who is Dr. Michael Bauer/ Kevin Rosenblatt?',
            A:'Dr. Bauer and Dr. Rosenblatt are clinicians at Aegis Health Services, partnered with your doctor to help improve your health outcomes through collaborative practice.'
        },
        {
            Q:'Why did my doctor refer me to Aegis?',
            A:'Your doctor identified you as a patient eligible for our services based on a criterion of medications and chronic conditions. These vary by site but usually are based off of high-risk medications, taking five or more medications, and/or having more than one chronic condition.'
        },
        {
            Q:'Who gave you permission to access my PHI?',
            A:'In the consent forms you signed with your treating physician, you gave your permission to allow the treating physician to make medical decisions on your behalf which included partnering with other physicians to deliver you better patient care. We are one of those partnerships, working with your doctor to conduct these services on their behalf.'
        },
        {
            Q:'Why am I receiving a letter from my insurance?',
            A:'Services that are billed to insurance will always result in an “explanation of benefits” - this does not necessarily mean that you will owe out-of-pocket for services.'
        },
        {
            Q:'Why am I receiving a statement from Aegis Health Services?',
            A:'We billed your insurance, and they processed the claim. The balance we are billing you for is the patient responsibility (copays, coinsurance, and deductibles) determined by your insurance company.'
        },
        {
            Q:'How do I pay my bill?',
            A:'We accept all forms of payment. You can pay your bill directly online via the patient portal or you can send payment to:',
            isBilling:true,
            link:'https://patientportal.advancedmd.com/account/logon?lk=147397'
        },
    ]

   
console.count()

    return(
        <><Box className='flex-col-center' >
            <Grid container className='flex-col max-width' m={'auto'} gap={'56px'} p='56px 0px'>
                <Grid xs={10} lg={12} className='FAQs-header'  m={'auto'}>
                    <Typography sx={{ fontWeight: '600', fontSize: '30px' }}>Patient FAQs</Typography>
                    <br />
                    <Typography sx={{ fontWeight: '400', fontSize: '16px' }}>Welcome to the Aegis Health Services/BeyondMD FAQs. Here, you'll find brief, informative answers to your queries about our team, services, privacy policies, insurance, and billing. Your health and satisfaction are our utmost priority.</Typography>
                </Grid>
                <Grid xs={10} lg={12} m={'auto'}>
                    {FAQs.map((FAQ, index) => {
                        return (
                            <QAAccordion key={index} question={FAQ.Q} answer={FAQ.A} isBilling={FAQ?.isBilling} link={FAQ.link} />
                        );
                    })}
                </Grid>
            </Grid> 
            
        </Box>
       </>
    )
}

/*


*/
