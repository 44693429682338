
import './Header.css'
import mainLogo from '../static/beyondMD.png'
import { useNavigate } from 'react-router-dom'
import { Button, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; 
import { useState, useEffect } from 'react';
import MobileHeader from './MobileHeader';


export default function Header(){
    let navigate = useNavigate(); 
    const [tab, setTab] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth < 620? true: false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
      };
    
      const handleDrawerClose = () => {
        setDrawerOpen(false);
      };

    //choose the screen size 
    const handleResize = () => {
        
        if (window.innerWidth < 620) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    
    // create an event listener
    useEffect(() => {
        window.addEventListener('resize', handleResize);
    })

    const toCareers = () =>{
        setTab('careers')
        navigate('/careers')
        setDrawerOpen(false)
    }

    const toAbout = () =>{
        setTab('about')
        navigate('/about')
        setDrawerOpen(false)
    }

    const toInquiry = () =>{
        setTab('inquiry')
        navigate('/inquiry')
        setDrawerOpen(false)
    }

    const toHome = () =>{
        setTab('home')
        navigate('/')
    }

    const toPatients = () => {
        setTab('patients')
        navigate('/patients/FAQs')
        setDrawerOpen(false)
    }

    const btnStyles = {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '143%',
        letterSpacing: 0.17,
        color:'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        p:'8px, 11px',
        textTransform:'none',
        borderRadius:'21px',
        '&:hover': {
            backgroundColor: 'rgba(0, 178, 149, 0.3)',
          },
    }


    const containerStyle = {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        p:'12px 10px',
        position: 'sticky', 
        top: 0, 
        zIndex: 1000,
        background:'#fff'
    }

    return(
        <>
        {
        isMobile? 
            <MobileHeader 
                toAbout={toAbout} 
                toCareers={toCareers} 
                toInquiry={toInquiry} 
                toPatients={toPatients}
                tab={tab} 
                handleDrawerClose={handleDrawerClose} 
                handleDrawerOpen={handleDrawerOpen} 
                toHome={toHome} 
                drawerOpen={drawerOpen}/>:    
            
            <Grid container className='flex-row-center' sx={containerStyle}> 
            <Grid xs={12} className="flex-row max-width" justifyContent={'space-between'}>
                <img src={mainLogo} className='header-logo' style={{padding:'6px 0px', height:'32px', width:'182px !important'}} alt="BeyondMD Logo" onClick={toHome}/>
                <Grid className='flex-row-center' gap={3}>
                    <Grid className='flex-row-center' gap={2}>
                        <Button sx={{...btnStyles, width:'79px', height:'36px', color:tab==='about'?'#00B295':'black'}} onClick={toAbout}>About us</Button>
                        <Button sx={{...btnStyles, width:'72px', height:'36px', color:tab==='careers'?'#00B295':'black'}} onClick={toCareers}>Careers</Button>
                        <Button sx={{...btnStyles, width:'72px', height:'36px', color:tab==='patients'?'#00B295':'black'}} onClick={toPatients}>Patients</Button>
                    </Grid>
                    <Button className='sc-btn' 
                        sx={{
                            ...btnStyles, background:'#00B295',
                            color:'#fff', p:'8px 22px',
                            '&:hover':{
                                background: '#009978',
                                boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                            }
                            }} 
                    onClick={toInquiry}>Schedule Consultation</Button>
                </Grid>
            </Grid>
        </Grid>}
        

        </>
    )

}