import './Footer.css'
import justLogo from '../static/justlogo.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, useNavigate } from 'react-router-dom';


export default function Footer(){
    let navigate = useNavigate()

    const toAbout = () =>{
        navigate('/about')
    }

    const toInquiry =() =>{
        navigate('/inquiry')
    }

    return(
        <>
        <div className="footer-container">
            <div className="footer-top">
                <div className='top-container'>
                    <img src={justLogo} className='footer-logo' alt='BeyondMD Logo' />


                    <div className='footer-item'>
                        <h2>BEYONDMD</h2>
                        <p onClick={toAbout} className='footer-link'>About us</p>
                        <p onClick={toInquiry} className='footer-link'>Schedule consultation</p>
                    </div>

                    <div className='footer-item'>
                        <h2>CONTACT</h2>
                        <p>Email: info@beyondmd.care</p>
                        <p>Phone: (404) 793 - 8372</p>
                        
                    </div>

                    <div className='footer-item'>
                        <h2>SOCIAL</h2>
                        <div className='socials-container'>
                            <Link to='https://www.linkedin.com/company/beyondmdaco'>
                                <LinkedInIcon className='footer-link'/>
                            </Link> 
                        </div>
                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <p>© 2023 BeyondMD, Inc.</p>
            </div>
        </div>

        </>

    )
}