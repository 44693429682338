import { Button, IconButton, Drawer } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; 
import mainLogo from '../static/beyondMD.png'
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

export default function MobileHeader({toAbout, toInquiry, toCareers,tab, toHome, handleDrawerClose, handleDrawerOpen, drawerOpen,toPatients}){

    const btnStyles = {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '143%',
        letterSpacing: 0.17,
        color:'black',
        '&:hover': {
            backgroundColor: 'rgba(0, 178, 149, 0.3)',
          },
    }

    const containerStyle = {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        p:'12px 10px',
        position: 'sticky', 
        top: 0, 
        zIndex: 1000,
        background:'#fff'
    }

    return(
        <Grid container className='flex-row-center' sx={containerStyle} >
      <Grid xs={12} className="flex-row max-width" justifyContent={'center'}>
        <img src={mainLogo} className='header-logo' style={{padding:'6px 0px', height:'32px', width:'182px !important', marginLeft:'auto'}}  alt="BeyondMD Logo" onClick={toHome}/>
        <IconButton edge="end"  onClick={handleDrawerOpen} sx={{ marginLeft:'auto', paddingRight:'15px'}}>
            <MenuIcon sx={{height:'30px', width:'30px'}} />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
            <List>
            <IconButton  onClick={handleDrawerClose} sx={{ marginLeft:'auto'}}>
            <ChevronRightIcon sx={{height:'30px', width:'30px'}}/>
        </IconButton>
                <ListItem  disablePadding>
                    <ListItemButton onClick={toAbout} sx={btnStyles}>
                        <ListItemIcon>
                            <InfoOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'About us'} />
                    </ListItemButton>
                </ListItem>
                <ListItem  disablePadding>
                    <ListItemButton onClick={toCareers} sx={btnStyles}>
                        <ListItemIcon>
                            <WorkOutlineIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Careers'} />
                    </ListItemButton>
                </ListItem>
                <ListItem  disablePadding>
                    <ListItemButton onClick={toInquiry}  sx={btnStyles}>
                        <ListItemIcon>
                            <MailOutlineIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Schedule Consultation'} sx={{color:'black'}} />
                    </ListItemButton>
                </ListItem>
                <ListItem  disablePadding>
                    <ListItemButton onClick={toPatients}  sx={btnStyles}>
                        <ListItemIcon>
                           <PermIdentityOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Patients'} sx={{color:'black'}} />
                    </ListItemButton>
                </ListItem>
           
            </List>
        </Drawer>
      </Grid>
    </Grid>
    )
}




/*

<Grid container direction="column" gap={2}>
                <Button sx={{...btnStyles, width:'79px', height:'36px', color:tab==='about'?'#00B295':'black'}} onClick={toAbout}>About us</Button>
                <Button sx={{...btnStyles, width:'72px', height:'36px', color:tab==='careers'?'#00B295':'black'}} onClick={toCareers}>Careers</Button>
                <Button className='sc-btn' 
                    sx={{
                    ...btnStyles, background:'#00B295',
                    color:'#fff', p:'8px 22px',
                    '&:hover':{
                        background: '#009978',
                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
                    }
                    }} 
                    onClick={toInquiry}>Schedule Consultations</Button>
            </Grid>

*/